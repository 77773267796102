<template>
  <v-container fluid>
    <v-row>
      <v-col md="8" v-if="recentActivity.length > 0">
        <v-timeline align-top>
          <v-timeline-item
            v-for="(row, i) in recentActivity"
            :key="i"
            color="cyan"
            small
            right
          >
            <template v-slot:opposite>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="font-size-16 font-weight-500"
                    >{{ row.created_at }}</span
                  >
                </template>
                <span>{{ formatDateTime(row.added_at) }}</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-text class="white text--primary">
                <read-more
                  class="custom-read-more font-size-16 font-weight-500 line-height-1-4"
                  more-str="read more"
                  :text="row.description ? row.description : ''"
                  link="#"
                  less-str="read less"
                  :max-chars="100"
                >
                </read-more>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-col md="6" offset-md="3" v-else>
        <p class="m-0 ow-not-found text-center font-weight-500 font-size-16">
          <template v-if="pageLoading">
            <span class="py-8 d-block">Getting recent activities</span>
            <v-progress-linear
              color="cyan"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </template>
          <template v-else>
            <img
              :src="$assetURL('media/error/empty.png')"
              class="row-not-found-image mt-4"
            />
            <span class="d-block mt-4"
              >Uhh... There are no recent activity at the moment.</span
            >
          </template>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  name: "recent-activity",
  props: {
    user: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      pageLoading: true,
      recentActivity: []
    };
  },
  methods: {
    getUserActivity() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "user/" + _this.user + "/recent-activity" })
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    }
  },
  mounted() {
    const _this = this;
    _this
      .getUserActivity()
      .then(response => {
        _this.recentActivity = response;
      })
      .catch(error => {
        _this.logError(error);
      })
      .finally(() => {
        _this.pageLoading = false;
      });
  }
};
</script>
